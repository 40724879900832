<template>
  <section class='account'>
    <div class="account_wrap">
      <div class="left">
        <div class="authentication_wrap">
          去认证
          <el-popover placement="right" :width="200" trigger="click" content="认证之后才可以接到平台案源推送哦!">
            <template #reference>
              <span class="question">?</span>
            </template>
          </el-popover>
        </div>

        <el-upload class="upload-demo" action="#" :limit="1" :show-file-list="false" :http-request="handleUploadFile" ref="upload">
          <!-- <el-button size="small" type="primary">点击上传头像</el-button> -->
          <div class="avatar_wrap" v-if="ossUrl&&ossUrl.length>0">
            <img :src="ossUrl" alt="" class="icon">
          </div>
          <div class="avatar_wrap" v-else>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconyonghu"></use>
            </svg>
            上传头像
          </div>

        </el-upload>
        <div class="tab_wrap">
          <div class="tab_item" :class="{active: active==index}" v-for="(item, index) in tabArr" :key="index"
            @click="handleClickTab(index)">{{item}}</div>
        </div>
      </div>
      <div class="right">
        <PersonInfo v-show="active == 0" :ossUrl="ossUrl" @ossUrlClick="ossUrlClick" />
        <AccountSecurity v-show="active == 1" />
      </div>
    </div>
    <MyDialog></MyDialog>
  </section>
</template>

<script>
  import MyDialog from '@/components/myDialog'
  import PersonInfo from './PersonInfo'
  import AccountSecurity from './AccountSecurity'
  import {
    ElMessage
  } from 'element-plus'
  import {
    ref,
    reactive,
    defineComponent
  } from 'vue'
  import {
    uploadFile
  } from '@/api/account'
  export default defineComponent({
    name: 'Account',
    components: {
      PersonInfo,
      AccountSecurity,
      MyDialog
    },
    setup() {
      let upload = ref(null)
      const tabArr = reactive(['个人信息', '账号安全'])
      let active = ref(0)
      let ossUrl = ref('')
      const handleClickTab = (index) => {
        active.value = index
      }
      const beforeAvatarUpload = async (file) => {
        const isJPG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';
        if (!isJPG && !isPNG) {
          ElMessage.error("上传头像图片的格式只能是jpg/png格式")
        } else {
          let formData = new FormData()
          formData.append('file', file)
          formData.append("filepath", 'user-atvar')
          let res = await uploadFile(formData)
          console.log(res);
          if (res.code == 200) {
            ossUrl.value = res.data.ossUrl
          }
        }
        // return isPNG && isJPG
      }
      const ossUrlClick = (data) => {
        ossUrl.value = data
      }
      const handleUploadFile = (file) => {
        beforeAvatarUpload(file.file)
        upload.value.clearFiles()
      }
      return {
        tabArr,
        active,
        handleClickTab,
        beforeAvatarUpload,
        handleUploadFile,
        ossUrl,
        upload,
        ossUrlClick
      }
    }
  })
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  @import '@/assets/style/comman.scss';

  .account {
    width: 1200px;
    margin: 0 auto;
    height: 815px;
    border-radius: 20px;
    background: #fff;

    .account_wrap {
      display: flex;
      height: 100%;

      .left {
        width: 248px;
        border-right: 1px solid #edeff2;

        .authentication_wrap {
          margin: 40px 0 0 20px;
          height: 40px;
          display: flex;
          align-items: flex-end;
          color: $main-color;
          font-size: 14px;

          .question {
            margin-left: 10px;
            width: 14px;
            height: 14px;
            font-size: 12px;
            border-radius: 50%;
            border: 1px solid $main-color;
            text-align: center;
            line-height: 15px;
          }
        }

        .upload-demo {
          width: 100%;
          display: flex;
          justify-content: center;

          .avatar_wrap {
            color: $main-color;
            font-size: 12px;

            .icon {
              display: block;
              width: 80px;
              height: 80px;
            }

            img {
              width: 80px;
              height: 80px;
              border-radius: 50%;
            }
          }
        }

        .tab_wrap {
          width: 188px;
          margin: 20px auto 0;
          color: #797979;
          box-sizing: border-box;

          .tab_item {
            height: 68px;
            line-height: 68px;
            text-align: center;
            box-sizing: border-box;
            font-size: 14px;
            cursor: pointer;

            &.active {
              color: $main-color;
              border-right: 2px solid $main-color;
            }
          }
        }
      }

      .right {
        width: 745px;
        margin: 30px auto;
      }
    }
  }
</style>