<template>
	<div class="accountSecurity">
		<el-form ref="accountForm" :model="formData" :rules="rules" size="medium" label-width="120px" label-position="left">
			<el-form-item label="密码" prop="field101">
				<el-input v-model="formData.field101" 
				:style="{width: '100%'}" type="password"
				:disabled='true'>
					<template #suffix>
						<span class="update_text" @click="handleUpdate">修改</span>
					</template>
				</el-input>
			</el-form-item>
			<el-form-item label="微信" prop="field102">
				<el-input v-model="formData.field102" :placeholder="bindWxText" :disabled='true' clearable :style="{width: '100%'}">
					<template #suffix>
						<span class="update_text" @click="handleBindWX">{{bindText}}</span>
					</template>
				</el-input>
			</el-form-item>
		</el-form>
		<!-- 修改密码弹框 -->
		<MyDialog :isShow="showPsdDialog" title="修改密码" :closeDialog="closePsdDialog">
			<div class="psd_form">
				<el-form ref="psdForm" :model="psdFormData" :rules="psdRules" size="medium" label-width="80px"
					label-position="left">
					<el-form-item label="原密码" prop="password">
						<el-input v-model="psdFormData.password" placeholder="请输入原密码" clearable show-password
							:style="{width: '100%'}">
						</el-input>
					</el-form-item>
					<el-form-item label="新密码" prop="newPassword">
						<el-input v-model="psdFormData.newPassword" placeholder="请输入新密码" clearable show-password
							:style="{width: '100%'}"></el-input>
					</el-form-item>
					<el-form-item label="新密码" prop="newPasswordAgain">
						<el-input v-model="psdFormData.newPasswordAgain" placeholder="请再次输入新密码" clearable show-password
							:style="{width: '100%'}"></el-input>
					</el-form-item>
					<el-button type="primary" class="psd_btn" round @click="confirmUpdatePsd">确定修改</el-button>
				</el-form>
			</div>

		</MyDialog>
		<!-- 微信二维码弹框 -->
		<MyDialog :isShow="showWXDialog" title="绑定微信" :closeDialog="closeWXDialog">
			<div class="wx_dialog">
				<div class="img_wrap">
					<img :src="imgUrl" alt="">
				</div>
				<p>打开微信，扫描上方二维码完成绑定</p>
			</div>
		</MyDialog>
	</div>
</template>
<script>
	import MyDialog from '@/components/myDialog'
	import {
		updatePsd,
		bindWx
	} from '@/api/account'
	import {
		ElMessage
	} from 'element-plus'
	import {
		getTmpSubscribeQrCode
	} from '@/api/index.js';
	import {
		isPsd
	} from '@/utils'
	import {
		ref,
		reactive,
		onMounted
	} from 'vue'
	export default {
		components: {
			MyDialog
		},
		setup() {
			let bindWxText = ref('');
			onMounted(() => {
				wxOpenId.value = JSON.parse(sessionStorage.getItem("wxOpenId"))
				// console.log(wxOpenId.value);
				if(wxOpenId.value){
					bindText.value = '更换';
					bindWxText.value = '更换微信'
				}else{
					bindText.value = '去绑定';
					bindWxText.value = '请绑定微信'
				}
			})
			const validatePass = (rule, value, callback) => {
				if (value !== psdFormData.newPassword) {
					callback(new Error("两次输入的新密码不一致"))
				} else {
					callback()
				}
			}
			
			let wxOpenId = ref(null)
			let showPsdDialog = ref(false)
			let accountForm = ref(null)
			let psdForm = ref(null)
			let showWXDialog = ref(false)
			let timer = ref(null)
			let bindText = ref("绑定")
			let imgUrl = ref('')
			let formData = reactive({
				field101: '111111',
				field102: ''
			})
			let rules = reactive({
				field101: [{
					required: true,
					message: '请输入密码',
					trigger: 'blur'
				}],
				field102: [{
					required: true,
					message: '请绑定微信',
					trigger: 'blur'
				}],
			})
			let psdFormData = reactive({
				password: undefined,
				newPassword: undefined,
				newPasswordAgain: undefined,
			})
			let psdRules = reactive({
				password: [{
					required: true,
					message: '请输入原密码',
					trigger: 'blur'
				}, {
					min: 6,
					message: '密码长度最少6位',
					trigger: 'blur'
				}, {
					pattern: isPsd,
					message: '密码只能是数字,大小写字母或者符号',
					trigger: 'blur'
				}],
				newPassword: [{
					required: true,
					message: '请输入新密码',
					trigger: 'blur'
				}, {
					min: 6,
					message: '密码长度最少6位',
					trigger: 'blur'
				}, {
					pattern: isPsd,
					message: '密码只能是数字,大小写字母或者符号',
					trigger: 'blur'
				}],
				newPasswordAgain: [{
					required: true,
					message: '请再次输入新密码',
					trigger: 'blur'
				}, {
					min: 6,
					message: '密码长度最少6位',
					trigger: 'blur'
				}, {
					pattern: isPsd,
					message: '密码只能是数字,大小写字母或者符号',
					trigger: 'blur'
				}, {
					validator: validatePass,
					trigger: 'blur'
				}],
			})
			const submitForm = () => {
				accountForm.value.validate(valid => {
					if (!valid) return
					// TODO 提交表单
				})
			}
			const resetForm = () => {
				accountForm.value.resetFields()
			}
			const closePsdDialog = () => {
				showPsdDialog.value = false
			}
			const confirmUpdatePsd = () => {
				psdForm.value.validate(async valid => {
					if (valid) {
						let res = await updatePsd(psdFormData)
						if (res && res.code == 200) {
							ElMessage.success("修改成功")
							showPsdDialog.value = false
						}
					}
				})
			}
			const handleUpdate = () => {
				showPsdDialog.value = true
			}
			const handleBindWX = async () => {
				showWXDialog.value = true
					let res = await getTmpSubscribeQrCode({
						sceneStr: 'changeBind'
					})
					if(res.code == 200){
						imgUrl.value = `https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${res.data.ticket}`
						timer.value = setInterval(async () => {
							let res2 = await bindWx({ticket: res.data.ticket})
							if(res2.code == 200){
								// if(res.data.)
								if(res2.data.success){
									clearInterval(timer.value)
									if(res2.data.scanInfo.success){
										ElMessage.success("绑定成功")
										bindText.value = '更换';
										bindWxText.value = '更换微信'
										showWXDialog.value = false
									}else{
										ElMessage.error(res2.data.scanInfo.msg)
										clearInterval(timer.value)
									}
								}
							}
						}, 2000);
				}
			}
			const closeWXDialog = () => {
				showWXDialog.value = false
				clearInterval(timer.value)
			}
			return {
				bindWxText,
				accountForm,
				psdForm,
				formData,
				rules,
				submitForm,
				resetForm,
				showPsdDialog,
				psdFormData,
				psdRules,
				closePsdDialog,
				confirmUpdatePsd,
				handleUpdate,
				handleBindWX,
				showWXDialog,
				closeWXDialog,
				bindText,
				imgUrl,
				timer
			}
		}
	}
</script>
<style lang="scss" scoped>
	@import '@/assets/style/comman.scss';

	.accountSecurity {
		width: 500px;
		margin-top: 100px;

		.update_text {
			color: $main-color;
			cursor: pointer;
		}
	}

	.psd_form {
		width: 305px;
		margin: 30px auto 50px;

		.psd_btn {
			width: 100%;
		}
	}

	.wx_dialog {
		width: 300px;
		margin: 30px auto 50px;

		.img_wrap {
			width: 260px;
			height: 260px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 10px;
			box-shadow: 0 0 20px rgb(220, 223, 230);

			img {
				width: 230px;
				height: 230px;
			}
		}

		p {
			text-align: center;
			color: #606266;
			font-size: 14px;
			height: 40px;
			line-height: 40px;
		}
	}
</style>